const courses = [
  "App & Web Development Fundamentals",
  "Firebrand SCRUM master training",
  "ISTQB Software Testing",
  "MCSA Database fundamentals",
  "Microsoft Advanced Programming in C#",
  "TQUK - Principles of team leading",
  "Multiple Twilio superclasses",
];

export { courses };
